import { PaginationArrow } from "../../assets";

export default function Pagination({
  itemsCount,
  limit,
  currentPage,
  basePath,
  queryParams = {},
  onPageChange,
}: {
  itemsCount: number;
  limit: number;
  currentPage: number;
  basePath: string;
  queryParams?: { [key: string]: string | undefined };
  onPageChange?: (href: string) => void;
}) {
  const pageCount = Math.max(1, Math.ceil(itemsCount / limit));

  const getPagesToDisplay = (totalPages: number, activePage: number) => {
    const totalNumbers = 5; // Adjust this to control how many page numbers are shown
    const totalBlocks = totalNumbers + 2; // Including first and last pages

    if (totalPages <= totalBlocks) {
      return Array.from({ length: totalPages }, (_, i) => i + 1);
    }

    const pages: (number | string)[] = [];

    const leftBound = Math.max(2, activePage - 1);
    const rightBound = Math.min(totalPages - 1, activePage + 1);

    const hasLeftEllipsis = leftBound > 2;
    const hasRightEllipsis = rightBound < totalPages - 1;

    pages.push(1);

    if (hasLeftEllipsis) {
      pages.push('left-ellipsis');
    } else {
      for (let i = 2; i < leftBound; i += 1) {
        pages.push(i);
      }
    }

    for (let i = leftBound; i <= rightBound; i += 1) {
      pages.push(i);
    }

    if (hasRightEllipsis) {
      pages.push('right-ellipsis');
    } else {
      for (let i = rightBound + 1; i < totalPages; i += 1) {
        pages.push(i);
      }
    }

    pages.push(totalPages);

    return pages;
  };

  const pages = getPagesToDisplay(pageCount, currentPage);

  const handleClick = (pageNumber: number) => {
    const filteredQueryParams = Object.fromEntries(
      Object.entries(queryParams).filter(([value]) => value !== undefined),
    );

    const params = new URLSearchParams(
      filteredQueryParams as Record<string, string>,
    );

    if (pageNumber === 1) {
      params.delete('page');
    } else {
      params.set('page', pageNumber.toString());
    }

    const paramsString = params.toString();
    const href = paramsString ? `${basePath}?${paramsString}` : basePath;

    if (onPageChange) {
      onPageChange(pageNumber.toString());
    }
  };

  return (
    <div className="flex items-center justify-center gap-2">
      {currentPage > 1 ? (
        <button
          onClick={() => handleClick(currentPage - 1)}
          type="button"
          className="group flex h-8 w-8 items-center justify-center rounded-lg border border-muted-3 text-sm font-medium text-primary-1 hover:bg-primary-1 hover:text-primary-2"
        >
          <PaginationArrow right />
        </button>
      ) : (
        <span className="flex h-8 w-8 items-center justify-center rounded-lg border border-muted-3 text-sm font-medium text-muted-3">
          <PaginationArrow right />
        </span>
      )}

      {pages.map((page) => {
        if (page === 'left-ellipsis' || page === 'right-ellipsis') {
          return (
            <span
              key={page as string}
              className="flex h-8 w-8 items-center justify-center text-sm font-medium text-muted-3"
            >
              ...
            </span>
          );
        }
        if (page === currentPage) {
          return (
            <span
              key={`page-${page}`}
              className="flex h-8 w-8 cursor-default items-center justify-center rounded-lg border border-muted-3 bg-primary-1 text-sm font-medium text-primary-2"
            >
              {page}
            </span>
          );
        }
        return (
          <button
            type="button"
            key={`page-${page}`}
            onClick={() => handleClick(page as number)}
            className="flex h-8 w-8 items-center justify-center rounded-lg border border-muted-3 text-sm font-medium text-primary-1 hover:bg-primary-1 hover:text-primary-2"
          >
            {page}
          </button>
        );
      })}

      {currentPage < pageCount ? (
        <button
          type="button"
          onClick={() => handleClick(currentPage + 1)}
          className="group flex h-8 w-8 items-center justify-center rounded-lg border border-muted-3 text-sm font-medium text-primary-1 hover:bg-primary-1 hover:text-primary-2"
        >
          <PaginationArrow />
        </button>
      ) : (
        <span className="flex h-8 w-8 items-center justify-center rounded-lg border border-muted-3 text-sm font-medium text-muted-3">
          <PaginationArrow />
        </span>
      )}
    </div>
  );
}
