import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './context/authContext';
import ProtectedRoute from './context/protectedRoute';
import Login from './login';
import Home from './Home'; // Assuming you have a Home component
import Properties from './Properties';

function App() {
    return (
        <AuthProvider>
            <Router>
                <Routes>
                <Route path="/" element={<Login />} />
                <Route 
                    path="/home" 
                    element={
                        <ProtectedRoute>
                            <Home />
                        </ProtectedRoute>
                    } 
                />
                <Route 
                    path="/properties" 
                    element={
                        <ProtectedRoute>
                            <Properties />
                        </ProtectedRoute> 
                    } 
                />
                </Routes>           
            </Router>
        </AuthProvider>
    );
}

export default App;