import "./index.css";
import "react-datepicker/dist/react-datepicker.css";
import PropertyMap from "./components/propertyMap";

function Properties() {

  return (
    <div className="flex flex-col w-full h-screen p-2">
      <div className="flex flex-col lg:flex-row flex-1 w-full min-h-0 lg:space-x-2">
        {/* Map */}
        <div className="w-full z-0 h-full">
          <PropertyMap
            position={[41.332237, 19.836116]}
            zoom={13}
          />
        </div>
      </div>
    </div>
  );
}

export default Properties;
