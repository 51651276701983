/* eslint-disable @typescript-eslint/no-unused-expressions */
import L, { LatLngBoundsExpression, LatLngExpression, Map } from "leaflet";
import "leaflet-defaulticon-compatibility";
import "leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css";
import "leaflet/dist/leaflet.css";
import { useEffect, useRef, useState } from "react";
import {
  FeatureGroup,
  MapContainer,
  Marker,
  Polygon,
  Popup,
  TileLayer,
  useMap,
  ZoomControl,
} from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import { Property } from "../api/generated/graphql";

/** This component is used to set the initial view of the map
    If actualViewport is defined, it will fit the map to the bounds of the viewport
    Otherwise, it will set the map to the position and zoom provided
 */
const InitialMapView = ({
  actualViewport,
  position,
  zoom,
  polygonClicked,
}: {
  actualViewport: any;
  position: LatLngExpression;
  zoom: number;
  polygonClicked: any;
}) => {
  const map = useMap();

  useEffect(() => {
    if (polygonClicked) {
      map.setView(polygonClicked, zoom);
    } else if (actualViewport) {
      const bounds: LatLngBoundsExpression = [
        [actualViewport?.southwest?.lat, actualViewport?.southwest?.lng],
        [actualViewport?.northeast?.lat, actualViewport?.northeast?.lng],
      ];
      map.fitBounds(bounds);
    } else {
      map.setView(position, zoom);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, actualViewport, position, zoom]);

  return null;
};

export default function MyMap(props: {
  position: LatLngExpression;
  zoom: number;
  polygons?: any[];
  properties?: Property[];
  handlePolygonClick?: (coordinates: any) => void;
  selectedPolygon?: any;
  actualBounds?: any;
  handleNewPolygon?: (coordinates: any) => void;
  handleNewViewport?: (coordinates: any) => void;
  handleNewMarker?: (coordinate: any) => void;
  enableDraw?: boolean;
  actualViewport?: any;
  actualLocation?: any;
}) {
  const {
    position,
    polygons,
    zoom,
    handlePolygonClick,
    selectedPolygon,
    actualBounds,
    handleNewPolygon,
    handleNewViewport,
    handleNewMarker,
    enableDraw,
    actualViewport,
    actualLocation,
    properties,
  } = props;
  const theme = "light";

  const mapRef = useRef<Map | null>(null);
  const editableLayers = useRef<any>(null);
  const markerLayers = useRef<any>(new L.FeatureGroup());
  const [polygonClicked, setPolygonClicked] = useState<any>(null);

  // kur hap harten bounds, viewport dhe location shkojne si jane ne databaze
  useEffect(() => {
    if (actualBounds) {
      let bounds = actualBounds.map((coord: any) => [coord.lat, coord.lng]);
      handleNewPolygon && handleNewPolygon(bounds);
    }
    if (actualViewport) {
      let viewPort = {
        northeast: actualViewport.northeast,
        southwest: actualViewport.southwest,
      };
      handleNewViewport && handleNewViewport(viewPort);
    }
    if (actualLocation) {
      let coords = [actualLocation.lat, actualLocation.lng];
      handleNewMarker && handleNewMarker(coords);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actualBounds, actualViewport, actualLocation]);

  const handleDrawStart = (e: any) => {
    if (e.layerType === "polygon") {
      if (editableLayers.current) {
        editableLayers.current.clearLayers();
        handleNewPolygon && handleNewPolygon([]);
      }
    } else if (e.layerType === "marker" && markerLayers.current) {
      if (markerLayers.current) {
        markerLayers.current.clearLayers();
        handleNewMarker && handleNewMarker(null);
      }
    }
  };

  const handleCreated = (e: any) => {
    const layer = e.layer;
    if (e.layerType === "polygon") {
      editableLayers?.current?.addLayer(layer);
      const coords = layer
        .getLatLngs()[0]
        .map((coord: any) => [coord.lat, coord.lng]);
      handleNewPolygon && handleNewPolygon(coords);
    }

    if (e.layerType === "marker") {
      if (!markerLayers.current) {
        markerLayers.current = new L.FeatureGroup();
      }
      markerLayers.current.clearLayers();
      markerLayers.current.addLayer(layer); // Add the marker to the marker layers
      const coords = layer.getLatLng();
      handleNewMarker && handleNewMarker([coords.lat, coords.lng]);
    }
  };

  const handleEdited = (e: any) => {
    const layers = e.layers;
    layers.eachLayer((layer: any) => {
      if (layer instanceof L.Polygon) {
        const coords = (layer.getLatLngs()[0] as L.LatLng[]).map(
          (coord: any) => [coord.lat, coord.lng]
        );
        handleNewPolygon && handleNewPolygon(coords);
      }
      if (layer instanceof L.Marker) {
        const coords = [layer.getLatLng().lat, layer.getLatLng().lng];
        handleNewMarker && handleNewMarker(coords);
      }
    });
  };

  return (
    <div className="flex z-1 w-full h-full">
      <MapContainer
        attributionControl={false}
        ref={mapRef}
        className="w-full h-full"
        center={position}
        zoom={zoom}
        scrollWheelZoom
        zoomControl={false}
      >
        <InitialMapView
          actualViewport={actualViewport}
          position={position}
          zoom={zoom}
          polygonClicked={polygonClicked}
        />
        <ZoomControl position="bottomright" />
        <TileLayer
          url={`https://tiles.stadiamaps.com/tiles/alidade_smooth${
            theme === "light" ? "" : "_dark"
          }/{z}/{x}/{y}{r}.png?api_key=6ec6da2c-3e7e-4b4d-8ef7-4ba39357c366`}
        />
        {enableDraw && (
          <>
            <FeatureGroup ref={editableLayers}>
              <EditControl
                position="topright"
                onDrawStart={handleDrawStart}
                onCreated={handleCreated}
                onEdited={handleEdited}
                draw={{
                  polygon: {
                    allowIntersection: false,
                  },
                  marker: {
                    ref: markerLayers.current,
                  },
                  polyline: false,
                  rectangle: false,
                  circle: false,
                  circlemarker: false,
                }}
                edit={{
                  featureGroup: editableLayers.current,
                  remove: false,
                }}
              />
            </FeatureGroup>
          </>
        )}
        {properties?.map(
          (info) =>
            info.main_property.location?.geom && (
              <Marker
                key={info.id}
                position={
                  [
                    info.main_property.location?.geom?.coordinates[1],
                    info.main_property.location?.geom?.coordinates[0],
                  ] as LatLngExpression
                }
                alt={info.id}
              >
                <Popup
                  // ref={popupRef}
                  closeButton
                  autoPan={false}
                  maxWidth={512}
                >
                  <div>
                    <h3>
                      <b>Zone name:</b>{" "}
                      {info.main_property.location.city_zone?.zone_name}
                    </h3>
                    <h3>
                      <b>Formatted Address:</b>{" "}
                      {info.main_property.location.city_zone?.formatted_address}
                    </h3>
                    <p>
                      <b>Coordinates:</b>{" "}
                      {"[" +
                        info.main_property.location?.geom?.coordinates[1] +
                        "," +
                        info.main_property.location?.geom?.coordinates[0] +
                        "]"}
                    </p>
                  </div>
                </Popup>
              </Marker>
            )
        )}
        {polygons &&
          polygons.map((polygon, index) => {
            const isSelected =
              selectedPolygon &&
              JSON.stringify(selectedPolygon) === JSON.stringify(polygon);
            const pathOptions = isSelected
              ? { color: "red" }
              : { color: "blue" };

            return (
              <Polygon
                key={index}
                positions={polygon}
                pathOptions={pathOptions}
                fillColor="transparent"
                eventHandlers={{
                  click: () => {
                    handlePolygonClick && handlePolygonClick(polygon);
                    setPolygonClicked(polygons[index][0]);
                  },
                }}
              ></Polygon>
            );
          })}
        {actualBounds && (
          <Polygon positions={actualBounds} pathOptions={{ color: "green" }} />
        )}
      </MapContainer>
    </div>
  );
}
