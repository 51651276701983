/* eslint-disable @typescript-eslint/no-unused-expressions */
import L, { LatLngExpression, Map, Popup as P } from "leaflet";
import "leaflet-defaulticon-compatibility";
import "leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css";
import "leaflet/dist/leaflet.css";
import { useEffect, useRef, useState } from "react";

import {
  FeatureGroup,
  MapContainer,
  Marker,
  Polygon,
  Popup,
  TileLayer,
  ZoomControl,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import { Location_Set_Input, Property, useGetAllPropertiesLazyQuery, useUpdateLocationByPkMutation } from "../api/generated/graphql";
import Pagination from "../auth/components/pagination";
import { EditControl } from "react-leaflet-draw";
import { useForm } from "react-hook-form";
import PropertyZoneModal from "./propertyZoneModal";

export default function PropertyMap(props: {
  position: LatLngExpression;
  zoom: number;
}) {
  const { position, zoom } = props;
  const theme = "light";
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { isValid },
  } = useForm({ mode: "onChange" });
  const formRef = useRef<HTMLFormElement>(null);

  const popupRef = useRef<P>(null);
  const mapRef = useRef<Map | null>(null);
  const editableLayers = useRef<any>(null);

  const [getProperties] = useGetAllPropertiesLazyQuery();
  const [updateLocation] = useUpdateLocationByPkMutation();

  const [currentPage, setCurrentPage] = useState<number>(1);
  const limit = 100;
  const offset = (currentPage - 1) * limit;

  const [properties, setProperties] = useState<Property[]>([]);
  const [totalProperties, setTotalProperties] = useState<number>(0); 
  const [editableMarkerId, setEditableMarkerId] = useState<string | null>(null);
  const [editableProperty, setEditableProperty] = useState<Property | null>(null);
  const [polygon, setPolygon] = useState<[number, number][]>([]);
  const [newLocation, setNewLocation] = useState<[number, number] | undefined>(undefined);
  const [isLoadingResults, setIsLoadingResults] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [editableLocationZone, setEditableLocationZone] = useState<{zone_id: string | undefined, zone_name: string | undefined, city: string | undefined} | null>(null);

  const calculateSquareCoords = (
    coord1: [number, number],
    coord2: [number, number]
  ) => {
    const [lat1, lng1] = coord1;
    const [lat2, lng2] = coord2;

    const squareCoords: [number, number][] = [
      [lat1, lng1],
      [lat1, lng2],
      [lat2, lng2],
      [lat2, lng1],
    ];

    return squareCoords;
  };

  const handleDiagonalCoords = (
    coord1: [number, number],
    coord2: [number, number]
  ) => {
    const squareCoords = calculateSquareCoords(coord1, coord2);
    setPolygon(squareCoords);
  };


  useEffect(() => {
    if (editableMarkerId) {
      const marker = properties?.find((info) => info.id === editableMarkerId);
      const bounds = marker?.main_property.location?.city_zone?.bounds.map((coords: any) => [coords.lat, coords.lng]);
      if (bounds) {
        if (bounds.length === 2) {
          handleDiagonalCoords(bounds[0], bounds[1]);
        } else {
          setPolygon(bounds);
        }
      } else {
        setPolygon([]);
      }
    } else {
      setPolygon([]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editableMarkerId]);

  const fetchProperties = async (id?: string) => {
    const result = await getProperties(
      {
        variables: {
          limit: limit, 
          offset: id ? 0 : offset,
          where: {id: {_eq: id}}
        },
        fetchPolicy: "no-cache"
      }
    );
    setProperties(result.data?.property as Property[]);
    setTotalProperties(result.data?.property_aggregate.aggregate?.count as number);
    setIsLoadingResults(false);
    setPolygon([]);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!watch("property_id")) {
      fetchProperties();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const handlePageChange = (page: string) => {
    setCurrentPage(parseInt(page));
    setEditableMarkerId(null);
    setEditableProperty(null);
    setPolygon([]);

  };

   // Handle marker selection
   const handleMarkerClick = (property: Property) => {
    setEditableMarkerId(property.id);
    setEditableProperty(property);
  };

    // Handle marker edit completion
  const handleEditStop = () => {
    setEditableMarkerId(null);
  };

  const handleEdit = () => {
    if (!editableLayers.current) {
      return;
    }
    const layers = editableLayers.current.getLayers();
    layers.forEach((layer: any) => {
      if (layer instanceof L.Marker) {
        // Find the layer with the editable marker ID
        const editingMarker = editableLayers.current.getLayers().find(
          (layer: any) => layer.options.alt === editableMarkerId
        );
      
        if (editingMarker) {
          const { lat, lng } = editingMarker.getLatLng();
          setProperties((prevProperties) =>
            prevProperties.map((property) =>
              property.id === editableMarkerId && property.main_property.location
                ? {
                    ...property,
                    main_property: {
                      ...property.main_property,
                      location: {
                        ...property.main_property.location,
                        geom: {
                          ...property.main_property.location.geom,
                          coordinates: [lng, lat],
                        },
                      },
                    },
                  }
                : property
            )
          );
          setNewLocation([lat, lng]);
        }
      } 
    });
  };

  useEffect(() => {
    if (newLocation) {
      setModalIsOpen(true);
      setEditableLocationZone({
        zone_id: editableProperty?.main_property.location?.city_zone?.id,
        zone_name: editableProperty?.main_property.location?.city_zone?.zone_name,
        city: editableProperty?.main_property.location?.city_zone?.city.city_name
     });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newLocation]);

  const updateLoc = (zoneId?: string) => {
    setModalIsOpen(false);
    if (newLocation) {
    let newGeom = editableProperty?.main_property?.location?.geom;
    newGeom = {...newGeom, coordinates: [newLocation[1], newLocation[0]]};
    let set: Location_Set_Input = {
      geom: newGeom
    }
    if (zoneId) {
      Object.assign(set, {city_zone_id: zoneId});
    }
    updateLocation({variables: {id: editableProperty?.main_property?.location?.id, set: set}})
      .then((res) => {
        if (res.data?.update_location_by_pk) {
          if (watch("property_id")) {
            fetchProperties(watch("property_id"));
          } else {
            fetchProperties();
          }
        }
      });
    setNewLocation(undefined);
    setEditableProperty(null);
    setEditableLocationZone(null);
  }
  };

  const handleFormSubmit = (data: any) => {
    if (data.property_id) {
      setIsLoadingResults(true);
      setCurrentPage(1);
      fetchProperties(data.property_id);
    }
  };

  return (
    <div className="flex flex-col w-full h-screen p-2">
      {/* Top Section: Form and Buttons */}
      <div className="flex flex-row w-full justify-between">
        <div className="flex flex-col w-full overflow-x-auto">
          <form
            className="w-full pt-3"
            ref={formRef}
            onSubmit={handleSubmit(handleFormSubmit)}
          >
            <div className="grid grid-cols-4 gap-2 pb-3">

              {/* Property Id Input */}
              <div className="flex flex-col space-y-2">
                <label
                  htmlFor="property_id"
                  className="text-base truncate font-medium text-black mb-1"
                >
                  Property Id
                </label>
                <div className="w-full flex items-center p-2 border border-muted-3 rounded-lg">
                  <input
                    {...register("property_id")}
                    id="property_id"
                    placeholder="Property Id"
                    className="font-normal text-base text-primary-1 w-full bg-transparent focus:outline-none"
                    type="text"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>

          {/* Buttons */}
          <div className="flex flex-row justify-center items-end h-full space-x-3 py-3 pr-5 flex-none">
            <button
              type="submit"
              className={`rounded-lg w-full ${
                !isValid && "opacity-80"
              } bg-slate-900 px-3 py-2 w-72 h-fit text-white hover:opacity-80 flex items-center justify-center`}
              onClick={() =>
                formRef.current && handleSubmit(handleFormSubmit)()
              }
              disabled={!isValid}
            >
              {isLoadingResults ? (
                <>
                  <svg
                    className="animate-spin h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                </>
              ) : (
                "Apply Filters"
              )}
            </button>
            <button
              type="button"
              className={`rounded-lg w-72 h-fit ${
                !isValid && "opacity-80"
              } bg-slate-200 px-3 py-2 hover:opacity-80`}
              onClick={() => {
                reset({
                  property_id: null,
                });
                fetchProperties();
              }}
              disabled={!isValid}
            >
              Reset
            </button>
          </div>
      </div>
      <div className="relative w-full h-full">
        <MapContainer
          attributionControl={false}
          ref={mapRef}
          className="w-full h-full z-0"
          center={position}
          zoom={zoom}
          scrollWheelZoom
          zoomControl={false}
        >
          <ZoomControl position="bottomright" />
          <TileLayer
            url={`https://tiles.stadiamaps.com/tiles/alidade_smooth${
              theme === "light" ? "" : "_dark"
            }/{z}/{x}/{y}{r}.png?api_key=6ec6da2c-3e7e-4b4d-8ef7-4ba39357c366`}
          />

          {/* Non-editable markers */}
          {properties
            ?.filter((info) => info.id !== editableMarkerId)
            ?.map(
              (info) =>
                info.main_property.location?.geom && (
                  <Marker
                    key={info.id}
                    position={
                      [
                        info.main_property.location?.geom?.coordinates[1],
                        info.main_property.location?.geom?.coordinates[0],
                      ] as LatLngExpression
                    }
                    alt={info.id}
                    eventHandlers={{
                      click: () => handleMarkerClick(info),
                    }}
                  />
                )
            )}

          {/* Editable marker */}
          {editableMarkerId && (
            <FeatureGroup ref={editableLayers}>
              <EditControl
                position="topright"
                draw={{
                  polygon: false,
                  marker: false,
                  polyline: false,
                  rectangle: false,
                  circle: false,
                  circlemarker: false,
                }}
                edit={{
                  featureGroup: editableLayers.current,
                  remove: false,
                }}
                onEditStop={handleEditStop}
                onEdited={handleEdit}
              />
              {properties
                ?.filter((info) => info.id === editableMarkerId)
                ?.map(
                  (info) =>
                    info.main_property.location?.geom && (
                      <Marker
                        key={info.id}
                        position={
                          [
                            info.main_property.location?.geom?.coordinates[1],
                            info.main_property.location?.geom?.coordinates[0],
                          ] as LatLngExpression
                        }
                        alt={info.id}
                      >
                        <Popup
                          ref={popupRef}
                          closeButton
                          autoPan={false}
                          maxWidth={512}
                        >
                          <div>
                          <h3>
                            <b>Property Id:</b>{" "}
                            {
                              info.id
                            }
                          </h3>
                          <h3>
                              <b>Zone name:</b>{" "}
                              {
                                info.main_property.location.city_zone
                                  ?.zone_name
                              }
                            </h3>
                            <h3>
                              <b>Formatted Address:</b>{" "}
                              {
                                info.main_property.location.city_zone
                                  ?.formatted_address
                              }
                            </h3>
                            <p>
                              <b>Coordinates:</b>{" "}
                              {"[" +
                                info.main_property.location?.geom?.coordinates[1] +
                                "," +
                                info.main_property.location?.geom?.coordinates[0] +
                                "]"}
                            </p>
                          </div>
                        </Popup>
                      </Marker>
                    )
                )}
            </FeatureGroup>
          )}
          {polygon && (
            <Polygon positions={polygon} pathOptions={{ color: "green" }} />
          )}
        </MapContainer>
        <div className="absolute bottom-2 left-2 right-[52px] z-10 w-full flex items-center justify-center ">
          <div className="flex rounded-lg border border-muted-7 bg-muted-11 py-2 pl-4 pr-2 text-sm font-normal text-primary-1 items-center justify-center space-x-5">
            <Pagination 
              itemsCount={totalProperties} 
              limit={limit} 
              currentPage={currentPage} 
              basePath={"/properties"} 
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      </div>
      <PropertyZoneModal
        isOpen={modalIsOpen}
        zone={editableLocationZone}
        onClose={() => updateLoc()} 
        editZone={(zoneId) => updateLoc(zoneId) }
      />
    </div>
  );
}
