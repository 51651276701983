import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Modal from "react-modal";
import EditIcon from "../assets/edit-icon";
import MyMap from "./map";
import Select from "react-select";
import { useGetCitiesQuery } from "../api/generated/graphql";

interface EditModalProps {
  isOpen: boolean;
  zone: any;
  onClose: () => void;
  editZone: (zone: any) => void;
}

export default function EditModal({
  isOpen,
  zone,
  onClose,
  editZone,
}: EditModalProps) {
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { isValid },
  } = useForm({ mode: "onChange" });
  const formRef = useRef<HTMLFormElement>(null);
  const [editBounds, setEditBounds] = useState<any>([]);
  const [editViewport, setEditViewport] = useState<any>();
  const [editLocation, setEditLocation] = useState<any>();
  const [modalIsOpen, setModalIsOpen] = useState(isOpen);

  const [citiesOptions, setCitiesOptions] = useState<
    { label: string; value: string }[]
  >([]);

  const [isFirstStep, setIsFirstStep] = useState(true);

  const [newBounds, setNewBounds] = useState<any>([]);
  const [newViewport, setNewViewport] = useState<any>();
  const [newLocation, setNewLocation] = useState<any>();
  const [newSurfaceArea, setNewSurfaceArea] = useState<any>();

  const { data } = useGetCitiesQuery();

  useEffect(() => {
    if (data?.city) {
      const tempOptions = data.city.map((city) => ({
        label: city.city_name,
        value: city.city_name,
      }));
      setCitiesOptions(tempOptions);
    }
  }, [data]);

  useEffect(() => {
    if (zone) {
      setValue("city", {label: zone?.city.city_name, value: zone?.city.city_name});
      setValue("bounds", JSON.stringify(zone?.bounds));
      setValue("location", JSON.stringify(zone?.location));
      setValue("viewport", JSON.stringify(zone?.viewport));
      setValue("surface_area", zone?.surface_area);
      setValue("formatted_address", zone?.formatted_address);
    }
  }, [setValue, zone, isOpen]);

  useEffect(() => {
    setModalIsOpen(isOpen);
    Modal.setAppElement("body");
  }, [isOpen]);

  const handleFormSubmit = (formData: any) => {
    if (isFirstStep) {
      const selectedCityName = formData.city ? formData.city.value : null;
      const selectedCityId = data?.city.find(
        (city) => city.city_name === selectedCityName)?.id ;
      const zoneObject = {
        id: zone?.id,
      };
      if (selectedCityId && selectedCityName) {
        Object.assign(zoneObject, { city_id: selectedCityId });
        Object.assign(zoneObject, { city_name: selectedCityName });
      }
      if (formData.formatted_address) {
        Object.assign(zoneObject, { formatted_address: formData.formatted_address });
      }
      if (formData.bounds) {
        Object.assign(zoneObject, { bounds: JSON.parse(formData.bounds) });
      }
      if (formData.location) {
        Object.assign(zoneObject, { location: JSON.parse(formData.location) });
      }
      if (formData.viewport) {
        Object.assign(zoneObject, { viewport: JSON.parse(formData.viewport) });
      }
      if (newSurfaceArea) {
        Object.assign(zoneObject, { surface_area: newSurfaceArea });
      }
      editZone(zoneObject);
      setModalIsOpen(false);
      onClose();
    } else {
      setIsFirstStep(true);
      if (newBounds.length) {
        setValue("bounds", JSON.stringify(newBounds));
      }
      if (newViewport) {
        setValue("viewport", JSON.stringify(newViewport));
      }
      if (newLocation) {
        setValue("location", JSON.stringify(newLocation));
      }
      if (newSurfaceArea) {
        setValue("surface_area", newSurfaceArea);
      }
    }
  };

  const calculateViewport = (coords: [number, number][], zoomFactor = 2) => {
    // Finding min and max values for lat and lng
    const lats = coords.map((coord) => coord[0]);
    const lngs = coords.map((coord) => coord[1]);
    const minLat = Math.min(...lats);
    const maxLat = Math.max(...lats);
    const minLng = Math.min(...lngs);
    const maxLng = Math.max(...lngs);

    // Center calculation
    const centerLat = (minLat + maxLat) / 2;
    const centerLng = (minLng + maxLng) / 2;

    // Differences
    const latDiff = maxLat - minLat;
    const lngDiff = maxLng - minLng;

    // Zoomed-out differences
    const newLatDiff = latDiff * zoomFactor;
    const newLngDiff = lngDiff * zoomFactor;

    // Calculating new viewport bounds
    const southwest = {
      lat: centerLat - newLatDiff / 2,
      lng: centerLng - newLngDiff / 2,
    };
    const northeast = {
      lat: centerLat + newLatDiff / 2,
      lng: centerLng + newLngDiff / 2,
    };

    return {
        northeast,
        southwest,
    };
  };

  const calculateSquareCoords = (
    coord1: [number, number],
    coord2: [number, number]
  ) => {
    const [lat1, lng1] = coord1;
    const [lat2, lng2] = coord2;

    const squareCoords: [number, number][] = [
      [lat1, lng1],
      [lat1, lng2],
      [lat2, lng2],
      [lat2, lng1],
    ];

    return squareCoords;
  };

  const handleDiagonalCoords = (
    coord1: [number, number],
    coord2: [number, number]
  ) => {
    const squareCoords = calculateSquareCoords(coord1, coord2);
    setEditBounds(squareCoords);
  };

  const openMap = () => {
    if (zone?.bounds.length === 2) {
      handleDiagonalCoords([zone.bounds[0]?.lat, zone.bounds[0]?.lng], [zone.bounds[1]?.lat, zone.bounds[1]?.lng]);
    } else {
      setEditBounds(zone.bounds);
    }
    setEditViewport(zone.viewport);
    setEditLocation(zone.location);
    setIsFirstStep(false);
  };


  const getNewBounds = (bounds: any) => {
    if (bounds.length) {
      const newBounds = bounds.map((bound: any) => ({
        lat: bound[0],
        lng: bound[1],
      }));
      setNewBounds(newBounds);
      const newViewport = calculateViewport(bounds);
      setNewViewport(newViewport);
      setNewSurfaceArea(calculatePolygonArea(bounds));
    }
  };

  const calculatePolygonArea = (coordinates: [number, number][]) => {
    // Earth radius in kilometers
    const R = 6371.0;

    // If there are only two coordinates, calculate the area as a bounding box
    if (coordinates.length === 2) {
      const [latNe, lonNe] = coordinates[0]; // Northeast corner
      const [latSw, lonSw] = coordinates[1]; // Southwest corner

      // Convert degrees to radians
      const latNeRad = (latNe * Math.PI) / 180;
      const latSwRad = (latSw * Math.PI) / 180;
      const lonNeRad = (lonNe * Math.PI) / 180;
      const lonSwRad = (lonSw * Math.PI) / 180;

      const deltaLon = lonNeRad - lonSwRad;
        const surfaceArea = Math.abs(
            R ** 2 * deltaLon * (Math.sin(latNeRad) - Math.sin(latSwRad))
        );
        return parseFloat((surfaceArea * 1_000_000).toFixed(2)); // Convert to meters squared, round to 2 decimals
      } else {
        let totalArea = 0;
        const n = coordinates.length;

        for (let i = 0; i < n; i++) {
          const [lat1, lon1] = coordinates[i];
          const [lat2, lon2] = coordinates[(i + 1) % n];

          // Convert latitudes and longitudes to radians
          const lat1Rad = (lat1 * Math.PI) / 180;
          const lat2Rad = (lat2 * Math.PI) / 180;
          const lonDiffRad = ((lon2 - lon1) * Math.PI) / 180;

          // Spherical excess formula
          totalArea += lonDiffRad * (2 + Math.sin(lat1Rad) + Math.sin(lat2Rad));
      }
      totalArea = Math.abs(totalArea) / 2 * (R ** 2);
      return parseFloat((totalArea * 1_000_000).toFixed(2)); // Convert to meters squared, round to 2 decimals
      }
  };

    // Custom styles for react-select to ensure full width
    const customSelectStyles = {
      control: (provided: any) => ({
        ...provided,
        width: "100%",
      }),
      menu: (provided: any) => ({
        ...provided,
        zIndex: 9999,
      }),
    };

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        ariaHideApp={false}
        overlayClassName="blur-overlay"
        style={{
          content: {
            borderRadius: "12px",
            top: "50%",
            left: "50%",
            position: "absolute",
            bottom: "auto",
            transform: "translate(-50%, -50%)",
            width: "calc(100% - 20rem)",
            height: "h-full",
            minHeight: "50%",
            display: "flex",
            flexDirection: "column",
            outline: "none",
          },
        }}
      >
        <div className=" h-full w-full overflow-hidden rounded-xl px-5">
          <h1 className="text-lg font-bold">
            {isFirstStep ? "Edit" : "Draw a polygon"}
          </h1>
          <hr className="mt-3" />
          <div className="flex flex-col w-full h-full min-h-96">
            {isFirstStep ? (
              <form
                className="w-full pt-3"
                ref={formRef}
                onSubmit={handleSubmit(handleFormSubmit)}
              >
                <div className="flex flex-col space-y-5">
                  <div className="grid grid-cols-3">
                  <p className="text-sm">
                    <span className="font-bold">Zone name:</span>{" "}
                    {zone?.zone_name}
                  </p>
                  <p className="text-sm">
                    <span className="font-bold">Location Type:</span>{" "}
                    {zone?.location_type}
                  </p>
                  <p className="text-sm">
                    <span className="font-bold">Types:</span> [
                    {zone?.types.map((itm: string, index: number) => (
                      <span key={index}>
                        "{itm}"{index < zone.types.length - 1 ? ", " : ""}
                      </span>
                    ))}
                    ]
                  </p>
                  </div>
                  {/* City Select */}
                  <div className="flex flex-col space-y-2">
                    <label
                      htmlFor="city"
                      className="text-base font-medium truncate text-black mb-1"
                    >
                      City
                    </label>
                    <div className="w-full">
                      <Controller
                        control={control}
                        name="city"
                        render={({ field }) => (
                          <Select
                            {...field}
                            isClearable
                            options={citiesOptions}
                            placeholder="Select City"
                            className="w-full"
                            onChange={(selectedOption) => field.onChange(selectedOption)}
                            value={field.value}
                            styles={customSelectStyles}
                          />
                        )}
                      />
                    </div>
                  </div>
                  {/* Formatted Address */}
                  <div className="flex flex-col">
                    <label
                      htmlFor="formatted_address"
                      className="text-base truncate font-medium text-black mb-1"
                    >
                      Formatted Address
                    </label>
                    <div className="flex w-full space-x-2">
                      <div className="w-full flex items-center p-2 border border-muted-3 rounded-lg">
                        <input
                          {...register("formatted_address")}
                          id="formatted_address"
                          placeholder="Formatted Address"
                          className="font-normal text-left text-base text-primary-1 w-full bg-transparent focus:outline-none"
                          type="text"
                        />
                      </div>
                    </div>
                  </div>

                  {/* Bounds */}
                  <div className="flex flex-col">
                    <label
                      htmlFor="zone_name"
                      className="text-base truncate font-medium text-black mb-1"
                    >
                      Bounds
                    </label>
                    <div className="flex w-full space-x-2">
                      <div className="w-full flex items-center p-2 border border-muted-3 rounded-lg">
                        <input
                          {...register("bounds")}
                          id="bounds"
                          placeholder="Bounds"
                          className="font-normal text-left text-base text-primary-1 w-full bg-transparent focus:outline-none"
                          type="text"
                        />
                      </div>
                      <button type="button" onClick={openMap}>
                        <EditIcon />
                      </button>
                    </div>
                  </div>

                  {/* Surface */}
                  <div className="flex flex-col">
                    <label
                      htmlFor="surface_area"
                      className="text-base truncate font-medium text-black mb-1"
                    >
                      Surface Area
                    </label>
                    <div className="flex w-full space-x-2">
                      <div className="w-full flex items-center p-2 border border-muted-3 rounded-lg">
                        <input
                          {...register("surface_area")}
                          id="surface_area"
                          placeholder="Surface area"
                          className="font-normal text-base text-primary-1 w-full bg-transparent focus:outline-none"
                          type="text"
                        />
                      </div>
                    </div>
                  </div>

                  {/* Location */}
                  <div className="flex flex-col">
                    <label
                      htmlFor="location"
                      className="text-base truncate font-medium text-black mb-1"
                    >
                      Location
                    </label>
                    <div className="w-full flex items-center p-2 border border-muted-3 rounded-lg">
                      <input
                        {...register("location")}
                        id="location"
                        placeholder="Location"
                        className="font-normal text-base text-primary-1 w-full bg-transparent focus:outline-none"
                        type="text"
                      />
                    </div>
                  </div>


                  {/* View port */}
                  <div className="flex flex-col">
                    <label
                      htmlFor="viewport"
                      className="text-base truncate font-medium text-black mb-1"
                    >
                      Viewport
                    </label>
                    <div className="w-full flex items-center p-2 border border-muted-3 rounded-lg">
                      <input
                        {...register("viewport")}
                        id="viewport"
                        placeholder="Viewport"
                        className="font-normal text-base text-primary-1 w-full bg-transparent focus:outline-none"
                        type="text"
                      />
                    </div>
                  </div>

                </div>
              </form>
            ) : (
              <div className="w-full h-[600px] z-50">
                <MyMap
                  position={editBounds[1]}
                  zoom={13}
                  enableDraw={true}
                  actualBounds={editBounds}
                  actualViewport={editViewport}
                  actualLocation={editLocation}
                  handleNewPolygon={(bounds: any) => {
                    getNewBounds(bounds);
                  }}
                  // perdoret vetem ne momentin e inicializimit te hartes
                  handleNewViewport={(viewport: any) => {
                    setNewViewport(viewport);
                  }}
                  handleNewMarker={(coords: any) => {
                    if (coords) {
                      setNewLocation({lat: coords[0], lng: coords[1]});
                    }
                  }}
                />
              </div>
            )}

            {/* Buttons */}
            <div className="flex space-x-5 justify-end h-full py-3 mt-5 flex-none">
              <button
                type="submit"
                className={`rounded-lg w-32 ${
                  !isValid && "opacity-80"
                } bg-slate-900 px-3 py-2 text-white hover:opacity-80 flex items-center justify-center min-h-10`}
                onClick={() => handleSubmit(handleFormSubmit)() }>
                {isFirstStep ? "Edit" : "Apply Bounds"}
              </button>
              <button
                type="button"
                className={`rounded-lg w-32 ${
                  !isValid && "opacity-80"
                } bg-slate-200 px-3 py-2 hover:opacity-80`}
                onClick={() => {
                  isFirstStep ? onClose() : setIsFirstStep(true);
                }}
                disabled={!isValid}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
