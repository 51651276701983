interface PaginationArrowProps {
    right?: boolean;
  }
  
  function PaginationArrow({ right }: PaginationArrowProps) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        fill="none"
        className={`fill-current text-primary-1 group-hover:text-muted-5 ${right ? 'rotate-180' : ''}`}
      >
        <path d="M6.94 4 6 4.94 9.053 8 6 11.06l.94.94 4-4-4-4Z" />
      </svg>
    );
  }
  
  export default PaginationArrow;
  