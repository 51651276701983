import { useEffect, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Modal from "react-modal";
import { components, ControlProps, PropsValue } from "react-select";
import { useGetZonesLazyQuery } from "../api/generated/graphql";
import AsyncSelect from 'react-select/async';
import debounce from 'lodash.debounce';

interface PropertyZoneModalProps {
  isOpen: boolean;
  zone: {zone_id: string | undefined, zone_name: string | undefined, city: string | undefined} | null;
  onClose: () => void;
  editZone: (zone?: any) => void;
}

interface OptionType {
  value: string;
  label: string;
  description: string;
}

function CustomControl({
  children,
  ...props
}: ControlProps<OptionType, false>): JSX.Element {
  return (
    <components.Control {...props}>
      {children}
    </components.Control>
  );
}

export default function PropertyZoneModal({
  isOpen,
  zone,
  onClose,
  editZone,
}: PropertyZoneModalProps) {
  const {
    handleSubmit,
    setValue,
    control,
    formState: { isValid },
  } = useForm({ mode: "onChange" });
  const formRef = useRef<HTMLFormElement>(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [getCityZone] = useGetZonesLazyQuery();

  useEffect(() => {
    if (zone?.zone_id) {
      setValue("zone_name", {label: `${zone.zone_name} - ${zone.city}`, value: zone.zone_id});
    }
  }, [setValue, zone, isOpen]);

  useEffect(() => {
    setModalIsOpen(isOpen);
    Modal.setAppElement("body");
  }, [isOpen]);

  const handleFormSubmit = (formData: any) => {
      const zoneId: string = formData.zone_name.value;
      if (zoneId !== zone?.zone_id) {
        editZone(zoneId);
      } else {
        onClose();
      }
      setModalIsOpen(false);
  };

    // Use useRef for cache to maintain stability across renders
    const cacheRef = useRef<Record<string, any[]>>({});

    const debouncedFetch = useRef(
      debounce(async (inputValue: string, callback: (options: any[]) => void) => {
        if (cacheRef.current[inputValue]) {
          callback(cacheRef.current[inputValue]);
          return;
        }
        try {
          const { data } = await getCityZone({
            variables: {
              limit: 10,
              offset: 0,
              search: inputValue ? `%${inputValue}%` : '%',
            },
          });
          const options = data?.city_zone.map((city) => ({
            label: `${city.zone_name} - ${city.city.city_name}`,
            value: city.id,
          })) || [];
          cacheRef.current[inputValue] = options;
          callback(options);
        } catch (error) {
          console.error('Error loading options:', error);
          callback([]);
        }
      }, 300),
    ).current;

  const loadOptions = (
    inputValue: string,
    callback: (options: any[]) => void,
  ) => {
    debouncedFetch(inputValue, callback);
  };

  useEffect(
    () => () => {
      debouncedFetch.cancel();
    },
    [debouncedFetch],
  );

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        ariaHideApp={false}
        overlayClassName="blur-overlay"
        style={{
          content: {
            borderRadius: "12px",
            top: "50%",
            left: "50%",
            position: "absolute",
            bottom: "auto",
            transform: "translate(-50%, -50%)",
            width: "700px",
            height: "h-full",
            minHeight: "50%",
            display: "flex",
            flexDirection: "column",
            outline: "none",
          },
        }}
      >
        <div className=" h-full w-full overflow-hidden rounded-xl px-5">
          <h1 className="text-lg font-bold">
            Change zone 
          </h1>
          <hr className="mt-3" />
          <div className="flex flex-col justify-between w-full h-full min-h-96">
            <form
              className="w-full pt-3"
              ref={formRef}
              onSubmit={handleSubmit(handleFormSubmit)}
            >
              <div className="flex flex-col space-y-5">
              <div className="flex flex-col">
              <label htmlFor="location" className="mb-3 font-medium text-muted-2">
                Zone Name*
              </label>
              <Controller
                control={control}
                name="zone_name"
                rules={{ required: 'Zone Name is required' }}
                render={({ field }) => (
                  <AsyncSelect
                    {...field}
                    cacheOptions
                    defaultOptions
                    loadOptions={loadOptions}
                    placeholder="Search Zone Name"
                    components={{ Control: CustomControl }}
                    hideSelectedOptions
                    onChange={(selectedOption: OptionType | null) => field.onChange(selectedOption)}
                    value={field.value as PropsValue<OptionType>}
                    noOptionsMessage={() => 'No options found'}
                    loadingMessage={() => 'Loading...'}
                  />
                )}
              />
            </div>
              </div>
            </form>
            {/* Buttons */}
            <div className="flex space-x-5 justify-end h-full py-3 mt-5 flex-none">
              <button
                type="submit"
                className={`rounded-lg w-32 ${
                  !isValid && "opacity-80"
                } bg-slate-900 px-3 py-2 text-white hover:opacity-80 flex items-center justify-center min-h-10`}
                onClick={() => handleSubmit(handleFormSubmit)() }>
                Edit
              </button>
              <button
                type="button"
                className={`rounded-lg w-32 ${
                  !isValid && "opacity-80"
                } bg-slate-200 px-3 py-2 hover:opacity-80`}
                onClick={() => {
                  onClose()
                }}
                disabled={!isValid}
              >
                No
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
