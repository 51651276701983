import React, { useEffect, useState } from "react";
import EditModal from "./editModal";
import { useUpdateCityZoneByPkMutation } from "../api/generated/graphql";
import { QdrantClient } from "@qdrant/js-client-rest";
import Switch from "react-switch";
import { RightArrow } from "../assets";
import BottomArrow from "../assets/bottom-arrow";
import { format } from 'date-fns';
// import { QdrantClient, Filter, FieldCondition, UpdateResult } from '@qdrant/js-client-rest';

interface DetailsProps {
  searchResult: any[];
  getSelectedZone: (item: any, show: string | null) => void;
  getUpdatedZones: () => void;
}

const client = new QdrantClient({
  url: 'https://dev.qdrant.kaktu.al',
  apiKey: '690a01f8-49c5-4368-a036-8ecf48b2d31e',
  port: 443,
  https: true
});

const Details: React.FC<DetailsProps> = ({
  searchResult,
  getSelectedZone,
  getUpdatedZones,
}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedZoneForEdit, setSelectedZoneForEdit] = useState<any>(null);
  const [zones, setZones] = useState<any[]>([]);
  const [showOnMap, setShowOnMap] = useState<string | null>(null);

  const [updateZone] = useUpdateCityZoneByPkMutation();

  const [expandedStates, setExpandedStates] = useState<boolean[]>([]);
  const toggleExpand = (index: number) => {
    setExpandedStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = !newStates[index];
      return newStates;
    });
  };

  useEffect(() => {
    if (searchResult) {
      setZones(searchResult);
      setExpandedStates(new Array(searchResult.length).fill(false));
    }
  }, [searchResult]);

  const calculateSimilarZoneBounds = (bounds: any) => {
    const numberOfSimilarZone = zones.filter(
      (item) =>
        item.bounds.length === bounds.length &&
        bounds.every(
          (bound: any, index: number) =>
            item.bounds[index] &&
            item.bounds[index].lat === bound.lat &&
            item.bounds[index].lng === bound.lng
        )
    ).length;
    return numberOfSimilarZone.toString();
  };

  const editZone = async (object: any) => {
    if (object.id) {
      // Update the zone in the primary database
      const response = await fetch("https://dev-location.kaktu.al/changeBoundRegulated", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(object),
      });
  
      const data = await response.json();
      if (data) {
        getUpdatedZones();
      }
    }
  };

  const handleAsProblematic = (item: any) => {
    updateZone({
      variables: {
        id: item.id,
        set: {
          is_problematic: !item.is_problematic
        }
      }
    }).then(async (res) => {
      console.log('res', res);
      if (res.data?.update_city_zone_by_pk) {
        getUpdatedZones();
        try {
          console.log('rika')
          // Use setPayload to update the record with the given ID and fields
          const response = await client.setPayload(
            "location", // First argument: collection name
            {
              points: [item.id],
              payload: {
                is_problematic: !item.is_problematic
              },
              wait: false
            }
          );
          console.log('Update successful:', response.status);
        } catch (error) {
          console.error('Error updating record:', error);
        }
      }
    });
  };

  return (
    <div className="pt-5 pl-1 flex flex-col overflow-y-auto w-1/4 h-full min-h-0">
      {zones.length > 0 ? (
        zones.map((item: any, index: number) => (
          <div className="mb-5" key={item.id}>
            <p className="text-sm">
              <span className="font-bold">Id:</span> {item.id}
            </p>
            <p className="text-sm">
              <span className="font-bold">City:</span> {item.city.city_name}
            </p>
            <p className="text-sm">
              <span className="font-bold">Zone name:</span> {item.zone_name}
            </p>
            <p className="text-sm">
              <span className="font-bold">Formatted Address:</span>{" "}
              {item.formatted_address}
            </p>
            <p className="text-sm">
              <span className="font-bold">Surface Area:</span>{" "}
              {item.surface_area}
            </p>
            <p className="text-sm">
              <span className="font-bold ">Bounds: </span>
              <button onClick={() => toggleExpand(index)}>
                {expandedStates[index] ? <BottomArrow /> : <RightArrow />}
              </button>{" "}
              [
              {!expandedStates[index] ? (
                "...]"
              ) : (
                <>
                  <br />
                  {item.bounds.map((ss: any, idx: number) => (
                    <span key={idx}>
                      &nbsp;&nbsp;{`{ "lat": ${ss.lat}, "lng": ${ss.lng} }`}
                      {idx < item.bounds.length - 1 ? "," : ""}
                      <br />
                    </span>
                  ))}
                  ]
                </>
              )}
            </p>
            <p className="text-sm">
              <span className="font-bold">Location:</span>{" "}
              {`{ "lat": ${item.location.lat}, "lng": ${item.location.lng} }`}
            </p>
            <p className="text-sm">
              <span className="font-bold">Location Type:</span>{" "}
              {item.location_type}
            </p>
            <p className="text-sm">
              <span className="font-bold">Types:</span> [
              {item.types.map((itm: string, index: number) => (
                <span key={index}>
                  "{itm}"{index < item.types.length - 1 ? ", " : ""}
                </span>
              ))}
              ]
            </p>
            <p className="text-sm">
              <span className="font-bold">Data Method:</span>{" "}
              {item.data_method}
            </p>
            <p className="text-sm">
              <span className="font-bold">Zona me te njejtat bounds:</span>{" "}
              {calculateSimilarZoneBounds(item.bounds)}
            </p>
            <p className="text-sm">
              <span className="font-bold">Update at:</span>{" "}
              {format(new Date(item.updated_at), 'yyyy-MM-dd HH:mm:ss')}
            </p>
            <p className="text-sm">
              <span className="font-bold">Created at:</span>{" "}
              {format(new Date(item.created_at), 'yyyy-MM-dd HH:mm:ss')}
            </p>
            <div className="flex flex-row items-center space-x-3">
              <span className="text-sm font-bold">Is problematic: </span>
              <Switch
                onChange={() => handleAsProblematic(item)}
                checked={item.is_problematic}
              />
            </div>
            <div className="flex space-x-5 pr-5 mt-2">
              <button
                type="button"
                onClick={() => {
                  getSelectedZone(item, showOnMap === item.id ? null : item.id);
                  showOnMap === item.id ? setShowOnMap(null) :  setShowOnMap(item.id)}}
                className={`rounded-lg w-full bg-slate-900 text-white px-3 py-2 hover:opacity-80`}
              >
                {showOnMap === item.id ? 'Hide from map' : 'Show on map'}
              </button>
              <button
                type="button"
                onClick={() => {
                  setModalIsOpen(true);
                  setSelectedZoneForEdit(item);
                }}
                className={`rounded-lg w-full bg-slate-200 px-3 py-2 hover:opacity-80`}
              >
                Edit
              </button>
            </div>
            <hr className="mt-5 border" />
          </div>
        ))
      ) : (
        <p>No data</p>
      )}
      <EditModal
        isOpen={modalIsOpen}
        zone={selectedZoneForEdit}
        onClose={() => setModalIsOpen(false)}
        editZone={(object) => editZone(object)}
      />
    </div>
  );
};

export default Details;
